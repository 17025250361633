<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a href="#" target="_self" class="">
                    <a href="#/" class="active"><i class="fas fa-home"></i> </a>
                  </a>
                </li>
                <li class="breadcrumb-item" style="display: inline-block">
                  <router-link
                    class="active"
                    :to="{ name: 'ListTrafficProject' }"
                    >My Projects</router-link
                  >
                </li>
                <li
                  class="breadcrumb-item active"
                  style="display: inline-block"
                >
                  <span aria-current="location"
                    ><span data-v-99912124="">New Project</span></span
                  >
                </li>
              </ol>
            </nav>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Tạo dự án traffic mới</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <base-alert type="danger" v-if="errText !== ''">
            <strong>Lỗi!</strong> {{ errText }}
          </base-alert>

          <base-alert type="success" v-if="submitSuccess">
            <strong>Thêm dự án thành công!</strong>
          </base-alert>
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12">
              <base-input
                v-model="inputs.name"
                :inputClasses="errors.get('name') ? 'is-invalid' : ''"
                label="Tên dự án"
                :errors="errors.get('name') ? [errors.get('name')] : []"
                maxlength="255"
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input label="Size">
                <el-select v-model="inputs.size">
                  <el-option value="mini" label="Mini" />
                  <el-option value="medium" label="Medium" />
                </el-select>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input label="Time on each page">
                <el-select v-model="inputs.time_on_page" filterable>
                  <el-option
                    v-for="option in timeOnPageOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input
                label="URLs"
                :errors="errors.get('urls') ? [errors.get('urls')] : []"
              >
                <textarea
                  v-model="inputs.urls"
                  :class="[
                    errors.get('urls') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="5"
                ></textarea>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input
                label="1st Click URLs"
                :errors="
                  errors.get('second_urls') ? [errors.get('second_urls')] : []
                "
              >
                <textarea
                  v-model="inputs.second_urls"
                  :class="[
                    errors.get('second_urls') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="5"
                ></textarea>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input
                label="2nd Click URLs"
                :errors="
                  errors.get('third_urls') ? [errors.get('third_urls')] : []
                "
              >
                <textarea
                  v-model="inputs.third_urls"
                  :class="[
                    errors.get('third_urls') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="5"
                ></textarea>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input label="Traffic Type">
                <el-select v-model="inputs.traffic_type">
                  <el-option
                    v-for="option in trafficTypeOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input
                label="Referrers"
                :errors="
                  errors.get('referrers') ? [errors.get('referrers')] : []
                "
                v-if="inputs.traffic_type === 'referral'"
              >
                <textarea
                  v-model="inputs.referrers"
                  :class="[
                    errors.get('referrers') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="5"
                ></textarea>
              </base-input>

              <base-input
                label="Keywords"
                :errors="errors.get('keywords') ? [errors.get('keywords')] : []"
                v-if="inputs.traffic_type === 'organic'"
              >
                <textarea
                  v-model="inputs.keywords"
                  :class="[
                    errors.get('keywords') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="5"
                ></textarea>
              </base-input>

              <base-input
                label="Social Profiles"
                :errors="
                  errors.get('social_links') ? [errors.get('social_links')] : []
                "
                v-if="inputs.traffic_type === 'social'"
              >
                <textarea
                  v-model="inputs.social_links"
                  :class="[
                    errors.get('social_links') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="5"
                  placeholder="Full links to your social networks pages, for example, https://www.facebook.com/themostadvancedtrafficbot/, One per line"
                ></textarea>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input label="Speed">
                <base-slider v-model="inputs.speed"></base-slider>
                <div class="mt-3 row">
                  <div class="col-6">
                    <span class="range-slider-value">{{ inputs.speed }}%</span>
                  </div>
                </div>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input label="Bounce rate">
                <base-slider v-model="inputs.bounce_rate"></base-slider>
                <div class="mt-3 row">
                  <div class="col-6">
                    <span class="range-slider-value"
                      >{{ inputs.bounce_rate }}%</span
                    >
                  </div>
                </div>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input label="Return rate">
                <base-slider v-model="inputs.returningvisitors"></base-slider>
                <div class="mt-3 row">
                  <div class="col-6">
                    <span class="range-slider-value"
                      >{{ inputs.returningvisitors }}%</span
                    >
                  </div>
                </div>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input label="Devices">
                <el-select v-model="inputs.behaviour">
                  <el-option
                    v-for="option in devicesOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input label="Languages">
                <el-select v-model="inputs.languages" multiple filterable>
                  <el-option
                    v-for="option in languageNameOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input label="Countries Geo-Targeting">
                <el-select v-model="inputs.traffic_source" multiple filterable>
                  <el-option
                    v-for="option in trafficSourceOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="text-right">
            <base-button
              type="primary"
              native-type="submit"
              @click.prevent="onSubmit"
              :loading="isLoading"
              >Tạo dự án</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import _ from "lodash";
import BaseSlider from "@/components/BaseSlider";
import Errors from "@/utils/error";
import { removeAccents } from "../../../utils";

export default {
  name: "AddTrafficProject",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSlider,
  },
  data() {
    return {
      inputs: {
        title: "",
        name: "",
        size: "",
        keywords: "",
        urls: "",
        second_urls: "",
        third_urls: "",
        referrers: "",
        social_links: "",
        bounce_rate: 50,
        returningvisitors: 18,
        time_on_page: "5sec",
        languages: ["vi", "en"],
        speed: 100,
        behaviour: "realistic",
        traffic_type: "referral",
        traffic_source: ["VN"],
      },
      errors: new Errors(),
      errText: "",
      submitSuccess: false,
      isLoading: false,
      languageNameOptions: [],
      trafficSourceOptions: [],
      timeOnPageOptions: [
        { label: "5 seconds", value: "5sec" },
        { label: "30 seconds", value: "30sec" },
        { label: "1 minute", value: "1min" },
        { label: "2 minutes", value: "2min" },
        { label: "5 minutes", value: "5min" },
      ],
      devicesOptions: [
        { label: "Realistic Behaviour (30% Mobile)", value: "realistic" },
        { label: "Desktop Only (Win & Mac)", value: "desktop" },
        { label: "Mobile Only", value: "mobile" },
        { label: "Completely Random", value: "random" },
      ],
      trafficTypeOptions: [
        // {label: 'Direct', value: 'direct'},
        { label: "Referral", value: "referral" },
        // {label: 'Social', value: 'social'},
        // {label: 'Organic', value: 'organic'}
      ],
    };
  },
  created() {
    this.loadLanguages();
    this.loadCountries();
  },
  computed: {
    hasErrors() {
      return !_.isEmpty(this.errors.errors);
    },
  },
  methods: {
    resetForm() {
      this.inputs = {
        title: "",
        name: "",
        size: "",
        keywords: "",
        urls: "",
        second_urls: "",
        third_urls: "",
        referrers: "",
        social_links: "",
        bounce_rate: 50,
        returningvisitors: 18,
        time_on_page: "5sec",
        languages: ["vi", "en"],
        speed: 100,
        behaviour: "realistic",
        traffic_type: "referral",
        traffic_source: ["VN"],
      };
    },
    onSubmit() {
      let self = this;
      this.submitSuccess = false;
      this.errors = new Errors();
      this.errText = "";
      this.isLoading = true;
      // Remove Vietnamese special character and space from title
      self.inputs.title = removeAccents(self.inputs.name);

      this.$store
        .dispatch("traffic/addProject", self.inputs)
        .then(() => {
          this.resetForm();
          this.submitSuccess = true;
          this.isLoading = false;
          this.$notify({
            message: "Tạo traffic project thành công!",
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
          });

          // Redirect to project list page
          self.$router.push("/dashboard/traffic/projects");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503) {
            self.errText = "Tạo dự án không thành công xin vui lòng thử lại!";
          } else if (err.response.status === 400 && err.response.data.message) {
            self.errText = err.response.data.message;
          } else {
            self.errText = "Thông tin nhập vào không đúng, xin thử lại!";
          }

          this.$notify({
            message: self.errText,
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
          });

          self.isLoading = false;
        });
    },
    async loadLanguages() {
      const languages = await this.$store.dispatch("traffic/getLanguages");

      this.languageNameOptions = _.map(languages, function (language) {
        return { label: language.name, value: language.code };
      });
    },
    async loadCountries() {
      const countries = await this.$store.dispatch("traffic/getCountries");

      this.trafficSourceOptions = _.map(countries, function (country) {
        return { label: country.name, value: country.iso_code };
      });

      this.trafficSourceOptions.push({ label: "Global", value: "global" });
    },
  },
};
</script>

<style scoped></style>
